import { render, staticRenderFns } from "./menuElementsBusiness.vue?vue&type=template&id=25c7bb48&scoped=true&"
import script from "./menuElementsBusiness.vue?vue&type=script&lang=js&"
export * from "./menuElementsBusiness.vue?vue&type=script&lang=js&"
import style0 from "./menuElementsBusiness.vue?vue&type=style&index=0&id=25c7bb48&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25c7bb48",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VDivider})
