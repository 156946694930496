<template>
  <div class="fill-height d-flex flex-column">
    <v-divider v-if="$store.state.business.selectedBusiness.logo_dashboard" :style="`border-color : ${wsSIDEBARTEXT + 55}`"/>
    <!-- Return Button-->
    <menu-return-button
        v-if="!(!$store.state.dashboard.displayPageNavigation || pageNavigationFiltered.length < 2)"
        :page-navigation-filtered="pageNavigationFiltered"
        :admin="admin"
    />

    <!-- Main Navigation Button-->
    <div class="overflow-y-auto">
      <menu-element
          v-for="(item, i) in itemsFiltered" :key="i"
          @click="selectedItem = $event.value;action($event)"
          :item="item"
          :selected-item="selectedItem"
          :admin="admin"
          :is-page-navigation="!(!$store.state.dashboard.displayPageNavigation || pageNavigationFiltered.length < 2)"
      />
    </div>
    <!-- Spacer-->
    <div class="flex-grow-1"></div>
    <!-- Profile Bottom Navigation -->
    <div class="pb-6">
      <v-divider :style="`border-color : ${wsSIDEBARTEXT + 55}`"/>
      <menu-element
          v-for="(item, k) in items_profile" :key="k"
          @click="selectedItem = $event.value; action($event)"
          :item="item"
          :selected-item="selectedItem"
          :admin="admin"
      />
    </div>
    <!-- Spacer-->
    <a v-if="$store.state.business.selectedBusiness.logo_dashboard"
        href="https://westudy.ua/"
       target="_blank"
       class="font-weight-regular text-right mt-n5 mr-4 mb-1 noUnderline ">
      <h6 class="linkHover" :style="`color : ${wsSIDEBARTEXT + 77}`">
        Powered by <span class="font-weight-bold">WeStudy</span>
      </h6>
    </a>


  </div>


</template>

<script>

import {mapActions, mapMutations, mapState} from 'vuex';
import menuElement from "@/components/pages/businessDashboard/UI/sidebarBusiness/menuElement.vue";
import menuReturnButton from "@/components/pages/businessDashboard/UI/sidebarBusiness/menuReturnButton.vue";
export default {
  name: "menuElementsBusiness",
  components : {
    menuElement,
    menuReturnButton
  },
  props:{
    admin : {
      type : Boolean,
      default : false,
    }
  },
  data: () => ({
    selectedItem: 0,
    businessManager : false,
    parseFromRoute : '',
    loadedModules : []

  }),
  watch : {
    pageNavigation(value) {
      this.selectedItem = value
    }
  },
  computed: {
    ...mapState('cart', [ 'quantity']),
    ...mapState('dashboard', ['sideMenu','showBusinessToManager', 'pageNavigation']),
    ...mapState('business', ['selectedBusiness']),
    ...mapState('auth', ['userType']),

    ...mapState('messenger', [ 'newMessagesCount' ]),

    itemsFiltered() {
      let items = [];
      if (!this.$store.state.dashboard.displayPageNavigation || this.pageNavigationFiltered.length < 2) {
        this.authData.forEach((section) => {
          section.items.forEach(item => {
            items.push(item)
          })
        })
      } else {
        return this.pageNavigationFiltered
      }

      return items
    },
    pageNavigationFiltered() {
      if (this.$store.state.dashboard.pageNavigationItems.length === 0) {
        return []
      }
      let items = this.$store.state.dashboard.pageNavigationItems
      items = items.filter(el => !el.permission || this.MODULE_PERMISSIONS([el.permission], true))
      items = items.filter( el=> !el.auth   || el.auth.includes(this.userType)  )
      return items

    },
    items() {
      if (this.admin) {
        return this.items_admin
      }
      if (this.$store.state.dashboard.showBusinessToOwner || this.$store.state.dashboard.showBusinessToManager) {  return this.items_owner }
      else { return this.items_user }
    },
    showToUser() {
      if (this.$store.state.dashboard.showBusinessToOwner || this.$store.state.dashboard.showBusinessToManager) {  return false }
      else { return true }
    },
    authData() {

      let items = JSON.parse(JSON.stringify(this.items))

      items = items.filter( el=> !el.auth   || el.auth.includes(this.userType)  )
      items = items.filter( el=> !el.module || this.IS_MODULE_ACTIVE(el.module) )
      items = items.filter( el=> !el.disabled )




      if (this.showBusinessToManager ) {
        items = items.filter( el=> !el.permission || this.MODULE_PERMISSIONS([el.permission], true) )
      }

      items.forEach( (section) => {
        section.items = section.items.filter( el => !el.auth   || el.auth.includes(this.userType) )
        section.items = section.items.filter( el => !el.module || this.IS_MODULE_ACTIVE(el.module) )
        section.items = section.items.filter( el=> !el.roles || this.HAS_ROLES(...el.roles) )
        section.items = section.items.filter( el=> !el.disabled )


        if (this.showBusinessToManager ) {
          section.items = section.items.filter( el => !el.permission || this.MODULE_PERMISSIONS([el.permission], true)  )
        }

        section.items.forEach( (item) => {
          if ( item.children ) {
            item.children = item.children.filter( el => !el.auth   || el.auth.includes(this.userType) )
            item.children = item.children.filter( el => !el.module || this.IS_MODULE_ACTIVE(el.module) )
            item.children = item.children.filter( el => !el.disabled )

            if (this.showBusinessToManager ) {
              item.children = item.children.filter( el => !el.permission || this.MODULE_PERMISSIONS([el.permission], true) )
            }
          }
        })
        section.items = section.items.filter( el => !el.children || el.children.length > 0 )
      })
      return items;

    },

    items_user() {
      return [
        {
          section: this.$t('Dashboard'),
          items: [
            {
              text: this.$t('MyStudy'),
              icon: 'mdi-view-dashboard-outline',
              value : 'dashboard',
              route: this.businessDashLink(''),
            },
            {
              text: this.$t('Certificates'),
              icon: 'mdi-certificate',
              value : 'certificates',
              path: 'certificates',
            },
            {
              text: this.$t('Storage'),
              value: 'storage',
              icon: 'mdi-server',
              path: 'storage',
              module : "storage",
              disabled : !this.$store.state.business.selectedBusiness.enable_student_storage
            },
            // TODO: Gamification
            // {
            //   text: this.$t('Achievements'),
            //   value: 'student_achievements',
            //   // permission:'MODULE_GAMIFICATION',
            //   icon: 'mdi-star',
            //   path: 'student_achievements'
            // },
            // { text: 'Мої курси' , permission:'MODULE_WESTUDY'    , icon: 'mdi-school' , action: { type: 'route', path: 'courses/student'    } , module : 'westudy'},
            // {
            //   text: this.$t('AvailableCourses'),
            //   permission: 'MODULE_WESTUDY',
            //   icon: 'mdi-cast-education',
            //   value: 'westudy',
            //   path: 'courses/all',
            // },

            ...this.loadedModules.filter(el => el.user),


          ]
        },

      ]
    },
    items_owner() {
      return  [  {
        section: this.$t('MyBusiness'),
        items: [
          {
            text: this.$t('Dashboard'),
            value : 'dash',
            icon: 'mdi-view-dashboard-outline',
            route: this.businessDashLink(''),
          },
          {
            text: this.$t('Statistics'),
            value : 'dashboard',
            icon: 'mdi-monitor-dashboard',
            path: 'dashboard',
            auth : ['businessman'],
          },
          // {
          //   text: this.$t('News'),
          //   value: 'news',
          //   permission:'MODULE_NEWS' ,
          //   icon: 'mdi-newspaper-variant-outline',
          //   path: 'news',
          //   module : 'news'
          // },

          {
            text: this.$t('Courses'),
            value: 'courses',
            permission:'MODULE_WESTUDY',
            icon: 'mdi-school' ,
            path: 'courses',
            module : 'westudy',
            id :'sidebar_courses',
          },
          {
            text: this.$t('Products'),
            value: 'products',
            permission:'MODULE_PRODUCTS',
            icon: 'mdi-cart-variant' ,
            path: 'products',
            module : 'products',
            id :'products',
          },
          {
            text: this.$t('Orders'),
            value: 'orders',
            permission:'MODULE_ORDERS',
            icon: 'mdi-receipt-text-check-outline',
            path: 'orders',
            module : 'orders'
          },
          {
            text: this.$t('CoursesOrders'),
            value: 'westudy/orders',
            permission:'MODULE_WESTUDY-ORDERS',
            icon: 'mdi-receipt-text-check-outline',
            path: 'westudy/orders',
            module : 'westudy-orders'
          },
          ...this.loadedModules.filter(el => el.owner),
          {
            text: this.$t('DiscountSystem'),
            value: 'discount',
            permission:'MODULE_DISCOUNTS',
            icon: 'mdi-sale',
            module : 'discounts',
            children : [
              {
                text: 'Discounts',
                value: 'discount/permanent',
                path: 'discount/permanent',
                module : 'discounts',
                permission:'MODULE_DISCOUNTS',
              },
              {
                text: 'PromoCodes',
                value: 'discount/promo',
                path: 'discount/promo',
                module : 'discounts',
                permission:'MODULE_DISCOUNTS',
              },
              // {
              //   text: 'LoyaltySystem',
              //   value: 'discount/loyalty',
              //   path: 'discount/loyalty',
              //   module : 'discounts',
              //   permission:'MODULE_DISCOUNTS',
              // },
            ]
          },
          {
            text: this.$t('referral.system'),
            value: 'referral',
            permission:'MODULE_REFERRAL',
            icon: 'mdi-account-star',
            path: 'referral',
            module : 'referral'
          },
          // TODO: Gamification
          // {
          //   text: this.$t('Gamification'),
          //   value: 'gamification',
          //   // permission:'MODULE_GAMIFICATION',
          //   icon: 'mdi-robot',
          //   path: 'gamification'
          // },
          {
            text: this.$t('Leads'),
            value: 'leads',
            permission:'MODULE_LEADS',
            icon: 'mdi-card-account-phone-outline',
            path: 'leads'
          },
          {
            text: this.$t('Clients'),
            value: 'subscribers',
            permission:'MODULE_LEADS',
            icon: 'mdi-account-multiple',
            path: 'subscribers'
          },
          // {
          //   text: this.$t('Documentflow'),
          //   value: 'documentflow',
          //   icon: 'mdi-file-document-multiple-outline',
          //   path: 'documentflow'
          // },
          // {
          //   text: this.$t('ProjectManager'),
          //   value: 'project-manager',
          //   icon: 'mdi-sitemap-outline',
          //   path: 'project-manager'
          // },
          {
            text: this.$t('Newsletter'),
            value: 'newsletter',
            icon: 'mdi-mail',
            path: 'newsletter',
            module : 'finemailer',
            permission:'MODULE_FINEMAILER',
          },
          {
            text: this.$t('ChatBots'),
            value: 'chatbots',
            icon: 'mdi-robot' ,
            path: 'chatbots',
            module : 'chatbots',
            permission:'MODULE_CHATBOTS',
          },
          {
            text: this.$t('PlansAndPayments'),
            value: 'plans_payments',
            auth : ['businessman'],
            icon: 'mdi-credit-card-marker' ,
            path: 'plans_payments'
          },
          {
            text: this.$t('Reports'),
            value: 'statistics/courses',
            icon: 'mdi-chart-areaspline',
            path: 'statistics/courses',
            permission:'MODULE_STATISTICS',
          },
          {
            text: this.$t('WebSite'),
            value: 'editor',
            icon: 'mdi-palette',
            route: this.businessPublicLink('editor'),
            permission:'MODULE_TEMPLATES',
          },
          {
            text: this.$t('Storage'),
            value: 'storage',
            icon: 'mdi-server',
            path: 'storage',
            permission:'MODULE_STORAGE',
            module : "storage"
          },
          {
            text: 'Settings',
            value: 'settings',
            icon: 'mdi-cog-outline',
            module : "settings",
            children : [
              {
                text: 'General',
                value: 'settings/general',
                path: 'settings/general',
                module : 'settings',
                permission:'MODULE_SETTINGS__GENERAL',
              },
              {
                text: 'Payment',
                value: 'settings/payment',
                path: 'settings/payment',
                module : 'settings',
                permission:'MODULE_SETTINGS__PAYMENTS',
              },
              {
                text: 'CrmSystem',
                value: 'settings/crm',
                path: 'settings/crm',
                module : 'settings',
                permission:'MODULE_SETTINGS__CRM',
              },
              {
                text: 'Managers',
                value: 'settings/managers',
                path: 'settings/managers',
                module : 'managers',
                permission:'MODULE_SETTINGS__MANAGERS',
              },
              {
                text: 'Storage',
                value: 'settings/storage',
                path: 'settings/storage',
                module : 'storage',
                permission:'MODULE_SETTINGS__STORAGE',
              },
              {
                text: this.$t('settings.customization.title'),
                value: 'settings/customization',
                path: 'settings/customization',
              },
              {
                text: this.$t('settings.api.title'),
                value: 'settings/api',
                path: 'settings/api',
              },


            ]
          },

        ]
      }
      ]
    },
    items_profile() {
      let items = [

        {
          text: this.$t('MyProfile'),
          icon: 'mdi-badge-account-horizontal-outline',
          path: 'profile',
          value : 'profile'
        },
        { text: this.$t('Logout'),
          icon: 'mdi-logout-variant',
          action: 'logout',
          value : 'logout'
        }
      ]

      if ( !this.admin ) {
        if (this.messengerItem) {
          items.unshift(...this.messengerItem)
        }

        items.unshift(  {
          text: this.$t('Notifications'),
          icon: 'mdi-bell',
          messenger : true,
          path  : 'notifications',
          value : 'notifications',
          counter : this.recentEventsCount,
        })
      }

      return items
    },
    items_admin() {
      let sections =  [
        {
          section: 'Управління',
          items: [
            { text: this.$t('Dashboard'),
              icon: 'mdi-view-dashboard-outline',
              value : 'dashboard',
              route : this.businessDashLink('',true),
            },
            // { text: 'Навчання',
            //   icon: 'mdi-school-outline',
            //   value : 'courses',
            //   path : 'courses'
            // },
            // { text: 'Мої школи',
            //   icon: 'mdi-briefcase',
            //   value : 'businesses',
            //   path : 'businesses'
            // },
             // PARTNERS
            { text: 'Westudy Partner',
              roles : ['ROLE_PARTNER'],
              icon: 'mdi-school-outline',
              value : 'westudy_partner',
              path : 'westudy_partner'
            },

            // SUPER ADMIN
            { text: 'Тех підтримка',
              auth: ['superadmin'],
              adminRoles : ['WS_SUPPORT'],
              icon: 'mdi-face-agent',
              value : 'support',
              path : 'support'
            },
            { text: 'Бізнеси' ,
              auth: ['superadmin'],
              adminRoles : ['WS_BUSINESSES'],
              icon: 'mdi-briefcase-outline',
              value : 'businesses',
              path : 'businesses'
            },
            { text: 'Mobile Apps' ,
              auth: ['superadmin'],
              adminRoles : ['WS_BUSINESSES'],
              icon: 'mdi-cellphone',
              value : 'mobile',
              path : 'mobile'
            },
            { text: 'CRM' ,
              auth: ['superadmin'],
              adminRoles : ['WS_BUSINESSES'],
              icon: 'mdi-briefcase-outline',
              value : 'crm',
              path : 'crm'
            },
            { text: 'Оплати/Квоти',
              auth: ['superadmin'],
              adminRoles : ['WS_BUSINESSES_STATS'],
              icon: 'mdi-cash',
              value : 'payments',
              path : 'payments'
            },
            { text: 'Платежі/Cтатистика',
              auth: ['superadmin'],
              adminRoles : ['WS_ACCOUNTING'],
              icon: 'mdi-chart-line',
              value : 'accounting',
              path : 'accounting'
            },
            { text: 'Видатки',
              auth: ['superadmin'],
              adminRoles : ['WS_EXPENSES'],
              icon: 'mdi-abacus',
              value : 'expenses',
              path : 'expenses'
            },
            { text: 'Реферальна програма',
              auth: ['superadmin'],
              adminRoles : ['WS_REFERRAL'],
              icon: 'mdi-account-cash-outline',
              value : 'referral',
              path : 'referral'
            },
            { text: 'Завдання',
              auth: ['superadmin'],
              adminRoles : ['WS_TASKS'],
              icon: 'mdi-check',
              value : 'tasks',
              path : 'tasks'
            },
            { text: 'Заявки',
              auth: ['superadmin'],
              adminRoles : ['WS_LEADS'],
              icon: 'mdi-book-plus-multiple',
              value : 'clientLeads',
              path : 'clientLeads'
            },
            { text: 'Новини',
              auth: ['superadmin'],
              adminRoles : ['WS_NEWS'],
              icon: 'mdi-newspaper-variant-outline',
              value : 'news',
              path : 'news'
            },
            { text: 'FAQ',
              auth: ['superadmin'],
              adminRoles : ['WS_FAQ'],
              icon: 'mdi-help-circle-outline',
              value : 'faq',
              path : 'faq'
            },
            { text: 'Документація',
              auth: ['superadmin'],
              adminRoles : ['WS_KNOWLEDGE_BASE'],
              icon: 'mdi-notebook',
              value : 'documentation',
              path : 'documentation'
            },
            { text: 'Розсилка',
              auth: ['superadmin'],
              adminRoles : ['WS_NEWSLETTER'],
              icon: 'mdi-email-outline',
              value : 'newsletter',
              path : 'newsletter'
            },
            { text: 'Переклади',
              auth: ['superadmin'],
              adminRoles : ['WS_TRANSLATIONS'],
              icon: 'mdi-translate',
              value : 'translations',
              path : 'translations'
            },
            { text: 'Конвертер',
              auth: ['superadmin'],
              adminRoles : ['WS_CONVERTER'],
              icon: 'mdi-video',
              value : 'converter',
              path : 'converter'
            },

            ...this.loadedModules.filter(el => el.admin)

            // { text: 'Templates', auth: ['superadmin'], icon: 'mdi-account', action: {type: 'route', path: '/dashboard/templater'} },
            // { text: 'Domains', auth: ['superadmin'], icon: 'mdi-link-variant', action: {type: 'route', path: '/dashboard/domains'} },
            // { text: 'Users', auth: ['superadmin'], icon: 'mdi-account', action: {type: 'route', path: '/dashboard/users'} },

          ]
        },
      ]

      sections.forEach((section,index)=> {
        sections[index].items = sections[index].items.filter( item => !item.adminRoles || (this.HAS_ROLES(...item.adminRoles) || this.HAS_ROLES('ROLE_ULTRAADMIN' ) ) )
      })

      return sections
    },

    messengerItem() {
    if (!this.loadedModules.find(el => el.value === 'messenger')) {
      return []
    }
      return [{
        text: this.$t('Messenger'),
        icon: 'mdi-mail',
        messenger : true,
        path  : 'messenger',
        id : 'sidebar_messenger',
        value : 'messenger',
        counter : this.newMessagesCount
      }]
    },

    settingsItems() {
      let items = [
        { text : this.$t('Payment')     , auth : ['businessman'], permission:'MODULE_ONLINEPAYMENT',  value : 'payment'   , path : 'payment'   , icon : "mdi-credit-card" },
        { text : this.$t('Storage')     , value : 'storage'   , path : 'storage'   , icon : "mdi-server" },
        { text : this.$t('CrmSystem')   , value : 'crm'       ,  permission:'MODULE_LEADS', path : 'crm'       , icon : "mdi-card-account-phone-outline" },

        // { text : this.$t('Delivery')   , value : 'delivery'  , path : 'delivery'  , icon : "mdi-truck-delivery-outline" },
        { text : this.$t('Managers')    ,
          value : 'managers'  ,
          path : 'managers'  ,
          icon : "mdi-account-tie" ,
          permission:'MODULE_ONLINEPAYMENT',
        },

        { text : this.$t('WebSite') ,
          value : 'site'   ,
          path : 'site'   ,
          icon : "mdi-web",
          permission:'MODULE_TEMPLATES',
        }
      ]

      items = items.filter( el=> !el.module || this.IS_MODULE_ACTIVE(el.module) )

      if (this.showBusinessToManager ) {
        items = items.filter( el=> !el.permission || this.MODULE_PERMISSIONS([el.permission], true) )
      }


      return items
    }

  },
  methods: {
    ...mapMutations('dashboard', ['SHOW_MODAL_CONFIRM_LOGOUT', 'SET_PAGE_TITLE', 'TOGGLE_SIDEBAR']),
    ...mapActions('events', [ 'GET_RECENT_EVENTS' ]),
    ...mapActions('messenger', [ 'GET_RECENT_MESSAGES' ]),

    loadMenuItems() {
      const context = require.context('@modules', true, /menu\.js$/);

      console.log(context)
      context.keys().forEach(key => {
        const items = context(key).default;

        items.forEach(item => {
          item.text = this.$t(item.text)
          if (item.children && item.children.length > 0) {
            item.children.forEach(child => {
              child.text = this.$t(child.text)
            })
          }
        })

        this.loadedModules = [...this.loadedModules , ...items];
      });
    },


    select(item) {

      if ( this.SM && item.messenger ) {
        this.$store.state.messenger.displayMessenger = true
        this.$store.state.dashboard.sideMenuMobile = false
        return
      }

      switch (item.action.type) {

        case 'route':
          this.SET_PAGE_TITLE(item.text);
          this.$router.push(this.businessDashLink(item.action.path, this.admin))
          break;

        case 'logout':
          this.logout()
          break;
      }

      if ( this.SM) {
        this.$store.state.dashboard.sideMenuMobile = false
      }

    },
    action(item) {

      if ( !item.children ) {
        this.$store.state.dashboard.sideMenuMobile = false
      }

      switch ( item.action ) {
        case 'logout':
          this.logout()
          break;
      }
    },
    logout() {
      this.SHOW_MODAL_CONFIRM_LOGOUT();
    },

    //technical
    isSelected(value) {
      return this.selectedItem === value
    },
    checkRoute() {

      if ( this.$store.state.dashboard.displayPageNavigation ) {
        return
      }

      this.authData.forEach(section => {
        let currentRoute = section.items.find( el => el.path && this.$route.path === this.businessDashLink(el.path )  )
        if ( currentRoute ) {
          this.selectedItem = currentRoute.path
        }
      });

    }
  },
  beforeMount() {
    this.loadMenuItems()
  },
  created() {
    let route = this.$route.path.replace(this.businessDashLink('', this.admin),'')
    this.selectedItem = route ? route : 0
    this.checkRoute()
    this.GET_RECENT_EVENTS()
    this.GET_RECENT_MESSAGES()
  }
}
</script>

<style lang="scss" scoped>
.cut-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.two-lines-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


</style>